@import "./variable.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 14px;

  // @media (max-width: $md) {
  //   font-size: 15px;
  // }
  // @media (max-width: $xs) {
  //   font-size: 14px;
  // }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: $link-color;

  &:hover {
    color: rgba($color: $link-color, $alpha: 0.6);
    transition: color 150ms ease-in-out;
  }
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: 0em;
  fill: currentColor;
  overflow: hidden;
}

.link {
  display: inline;
  cursor: pointer;
  color: $link-color;

  &:hover {
    color: rgba($color: $link-color, $alpha: 0.6) !important;
    transition: color 150ms ease-in-out;
  }
}


#nprogress {
  position: relative;
  z-index: 1231;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $heading-color;
  font-weight: 600;
  margin: 0;
}

h1 {
  font-size: 2.2em;
}
h2 {
  font-size: 1.4em;
}
h3 {
  font-size: 1.2em;
}
h4 {
  font-size: 1.1em;
}
h5 {
  font-size: 1em;
}
h6 {
  font-size: 0.9em;
}
