$primary-color: #1a5fac;
$background-color: rgb(248, 248, 248);
$background-color-dark: rgba(#1a5fac, 0.1);
$background-color-dark2: #ffb94f;

$link-color: #1a5eac; // 链接色
$success-color: #52c41a; // 成功色
$warning-color: #faad14; // 警告色
$error-color: #f5222d; // 错误色
$font-size-base: 14px; // 主字号
$heading-color: rgba(0, 0, 0, 0.85); // 标题色
$text-color: rgba(0, 0, 0, 0.65); // 主文本色
$text-color-dark: #1a5fac;
$text-color-secondary: rgba(0, 0, 0, 0.45); // 次文本色
$disabled-color: rgba(0, 0, 0, 0.25); // 失效色

$border-color: rgba(0, 0, 0, 0.15);
$ide-bg-color: #1e1e1e;
$ide-theme-color: rgb(12, 106, 168);
