@import "./colors.scss";
/**
* 使用::before伪元素给dom添加边框，默认添加顶部边框
* 
* $color: 边框颜色
* $width: 边框宽度，单位（px）
* $position: 边框位置: "top" | "left" | "bottom" | "right"
*/
@mixin before-border($color: #eee, $width: 1, $position: "top") {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    background-color: $color;

    @if $position == "bottom" {
      left: 0;
      right: 0;
      top: 0;
      height: 1px;
      background-color: $color;
      transform: scaleY($width);
    } @else if $position == "right" {
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 1px;
      transform: scaleX($width);
    } @else if $position == "top" {
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 1px;
      transform: scaleY($width);
    } @else if $position == "left" {
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 1px;
      transform: scaleX($width);
    }
  }
}

/**
* 使用::aflter伪元素给dom添加边框，默认添加底部边框
* 
* $color: 边框颜色
* $width: 边框宽度，单位（px）
* $position: 边框位置: "top" | "left" | "bottom" | "right"
*/
@mixin after-border($color: #eee, $width: 1, $position: "bottom") {
  position: relative;

  &:after {
    position: absolute;
    content: "";
    background-color: $color;

    @if $position == "bottom" {
      left: 0;
      right: 0;
      top: 0;
      height: 1px;
      background-color: $color;
      transform: scaleY($width);
    } @else if $position == "right" {
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 1px;
      transform: scaleX($width);
    } @else if $position == "top" {
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 1px;
      transform: scaleY($width);
    } @else if $position == "left" {
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 1px;
      transform: scaleX($width);
    }
  }
}


/* 统一添加链接样式 */
@mixin linked($color: $link-color) {
  cursor: pointer;
  &:hover {
    color: $color;
    transition: color 150ms ease-in-out;
  }
}

/* 子元素强制居中 */
@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 文本行数限制，超出显示省略号。默认显示1行 */
@mixin text-lines($lines: 1) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @if $lines > 1 {
    white-space: inherit;
    display: -webkit-box;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
  }
}


.text-line1 {
  @include text-lines;
}
.text-line2 {
  @include text-lines(2);
}
.text-line3 {
  @include text-lines(3);
}
.text-line4 {
  @include text-lines(4);
}