
// breakpoints
$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;


// 根据断点显示/隐藏
.show-xs,
.show-sm,
.show-md,
.show-lg {
  display: none;
}

@media screen and (max-width: $xs) {
  .hide-xs {
    display: none;
  }
  .show-xs {
    display: block;
  }
}

@media screen and (max-width: $sm) {
  .hide-sm {
    display: none;
  }
  .show-sm {
    display: block;
  }
}

@media screen and (max-width: $md) {
  .hide-md {
    display: none;
  }
  .show-md {
    display: block;
  }
}

@media screen and (max-width: $lg) {
  .hide-lg {
    display: none;
  }
  .show-lg {
    display: block;
  }
}
