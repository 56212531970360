@import "../../../styles/colors.scss";

.root {
  position: relative;
  user-select: none;

  .item {

    .item-row {
      color: #ccc;
      line-height: 28px;
      height: 28px;
      cursor: pointer;
      &:hover {
        background-color: rgba($ide-theme-color, 0.2);
      }

      .item-label {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
      }
    }

    &-selected {
      & > div > .item-row {
        background-color: rgba($ide-theme-color, 0.8) !important;
      }
    }

    &-right-click {
      & > div > .item-row {
        background-color: rgba($ide-theme-color, 0.4) !important;
      }
    }
  }
}
